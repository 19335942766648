import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import Layout from '../components/layout/layout'
import Meta from '../components/meta/meta'
import ChatAlt2 from '../assets/icons/chat-alt-2.svg'
import DesktopComputer from '../assets/icons/desktop-computer.svg'
import EmojiHappy from '../assets/icons/emoji-happy.svg'
import Sparkles from '../assets/icons/sparkles.svg'
import Tool from '../assets/icons/tool.svg'
import Trello from '../assets/icons/trello.svg'
import Users from '../assets/icons/users.svg'
import ViewGrid from '../assets/icons/view-grid.svg'
import Zap from '../assets/icons/zap.svg'
// import Josh from '../assets/josh.jpg'
import Todd from '../assets/todd.jpg'
// import Bret from '../assets/bret.jpg'

interface Props {
  location: Location
}

const LearnMore: React.FC<Props> = ({ location }: Props) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Learn more about our approach" />
      <div className="section text-center d-flex align-items-center container-fluid">
        <div className="container-xs py-10">
          <h1>About</h1>
          <p className="font-size-lg px-2">
            Sodality is an alternative to traditional Venture Capital for
            entrepreneurs building impactful growth businesses.
          </p>
        </div>
      </div>
      <div className="section shape17 d-flex align-items-center bg-transparent-dark text-light container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs m-0">
            <h3>We should chat if you want to:</h3>
            <ul>
              <li>
                Focus on your <b>customers</b> and <b>team</b> rather than
                “fundable milestones”
              </li>
              <li>
                Preserve optionality for how you want to scale your business
              </li>
              <li>
                Maximize the amount of your business <b>you own</b>
              </li>
              <li>Work with investors who want to support your business</li>
              <li>
                Build a business which is not taking on intense technical
                challenges, competing in a winner-take-all category, or
                committed to racing toward a{' '}
                <a className="fancy-link" href="/is-VC-for-me/">
                  billion dollar valuation
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section minHeight d-flex align-items-center bg-white-sm container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs m-0">
            <h3>
              Real support for you and your team. <b>Yes,&nbsp;$$$</b>&#8230;
              but we also help you with:
            </h3>
            <ul>
              <li>
                Your foundational technical platform - choosing a tech stack,
                build vs. buy decisions, identifying, selecting and negotiating
                contracts with vendors, etc&#8230;
              </li>
              <li>
                Customer acquisition, prioritization, goal setting, and other
                key strategic focus areas
              </li>
              <li>Co-founder and early team recruiting</li>
              <li>
                Choosing the right lawyers, accountants, and other service
                providers to help you on your journey
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section minHeight shape7-11 d-flex align-items-center text-light bg-transparent-dark container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs m-0">
            <h3>Make the leap</h3>
            <p>
              We want to begin working with you as early as possible in your
              journey. We are here to help you make the leap into building your
              company, so we are happy to start working together even before
              your company exists / even before you’ve quit your old job. If you
              are passionate enough about a big problem to want to build a
              growth company to solve it, let’s chat.
            </p>
            <div>
              <a
                className="btn btn-lg btn-primary"
                href="/work-with-us/"
                role="button"
              >
                Apply
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="section shape3-5 bg-light minHeight bg1 d-flex align-items-center container-fluid">
        <div className="container-xl py-10">
          <div className="container-xs">
            <h3>We are</h3>
            <p>
              <a
                href="https://www.linkedin.com/in/joshscott/"
                className="less-fancy-link"
                target="_blank"
                rel="noreferrer"
              >
                <span>Josh Scott</span>
              </a>
              ,{' '}
              <a
                href="https://www.linkedin.com/in/toddtobin/"
                className="less-fancy-link"
                target="_blank"
                rel="noreferrer"
              >
                <span>Todd Tobin</span>
              </a>
              ,{' '}
              <a
                href="https://www.linkedin.com/in/bshanna/"
                className="less-fancy-link"
                target="_blank"
                rel="noreferrer"
              >
                <span>Bret Hanna</span>
              </a>{' '}
              complete with the bumps, bruises, emotional wounds and hopefully a
              little earned wisdom accumulated from spending the past couple of
              decades building growth businesses.
            </p>
            <p>
              From pre-founding ideation, to scaling to serve millions of
              customers, to acquisition by a big company.
            </p>
            <p>We’ve lived in the deep end of:</p>
            <div className="row mb-4 maxWidth">
              <div className="col-4">
                <img className="d-block m-0" src={DesktopComputer} alt="" />
                Engineering
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={ChatAlt2} alt="" />
                Product
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={Sparkles} alt="" />
                Design
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={Users} alt="" />
                Customer acquisition
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={ViewGrid} alt="" />
                Resource allocation
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={EmojiHappy} alt="" />
                People challenges
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={Zap} alt="" />
                Fundraising
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={Trello} alt="" />
                Strategy
              </div>
              <div className="col-4">
                <img className="d-block m-0" src={Tool} alt="" />
                Operations
              </div>
            </div>
            <div hidden>
              <a
                className="imageContainer"
                href="https://www.linkedin.com/in/toddtobin/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="d-block m-0" src={Todd} alt="Todd Tobin" />
                <span>Todd Tobin</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LearnMore
